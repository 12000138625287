import React from 'react'

// component
import MenuContent from '../../../Layout/MenuContent'

function Sashimi() {
  return (
    <div>
      <MenuContent value="sashimi" />
    </div>
  )
}

export default Sashimi