import React, { useState, useEffect } from 'react'
import { useOutletContext } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCartShopping } from '@fortawesome/free-solid-svg-icons'

// utils
import foodItems from '../_utils/food'

function MenuContent({ value }) {
  const [foodItemsInSection, setFoodItemsInSection] = useState([]);
  const [sectionHeader, setSectionHeader] = useState('');
  const [sectionDescription, setSectionDescription] = useState('');

  const saved_order = (localStorage.getItem('tsukiji_order') !== null) ? JSON.parse(localStorage.getItem('tsukiji_order')) : [];
  const [addedOrder, setOrder] = useState(saved_order);
  const [updateOrderCount] = useOutletContext();

  const addToOrder = (menu) => {
    let check_exist = addedOrder.some(item => item.menu_key === menu.menu_key);
    menu.quantity = 1; // add default quantity to 1
    if (!check_exist) {
      setOrder([...addedOrder, menu]);
    }
  }

  const removeOrder = (menu) => {
    const menuIdx = addedOrder.findIndex(order => order.menu_key === menu.menu_key);
    if (menuIdx === -1) { return; }
    let orders = [...addedOrder];
    orders.splice(menuIdx, 1);
    setOrder(orders)
  }

  const saveOrder = () => {
    updateOrderCount(addedOrder.length)
    localStorage.setItem('tsukiji_order', JSON.stringify(addedOrder));
  }

  const checkIfOrderExist = (menu_key) => {
    let exist = addedOrder.some(order => order.menu_key === menu_key);
    return exist;
  }

  useEffect(() => {
    foodItems.filter(sections => {
      return sections.label == value
    }).map(section => {
      setFoodItemsInSection(section.menuItems)
      setSectionHeader(section.label)
      setSectionDescription(section.description)
    })

    saveOrder()
  }, [addedOrder])

  return (
    <section id="menu-content" className="menu-content pt-0">
      <div className="container" data-aos="fade-up">
        <div className="section-header">
          <p className="fw-bold text-uppercase">{sectionHeader}</p>
          <h6><em>{sectionDescription}</em></h6>
        </div>
        <div className="row gy-5">
          {
            foodItemsInSection.map((item, idx) => {
              let menu_key = item['name'].replace(/\s+/g, '_').toLowerCase();
              item.menu_key = menu_key;
              let exist = checkIfOrderExist(menu_key)

              return (
                <div className="col-lg-4 menu-item" key={idx}>
                  <h4>{item.name} <em className="quantity">{item.description}</em> </h4>
                  <p className="ingredients">
                    {item.ingredients}
                  </p>
                  <p className="price">
                    £{item.price}
                  </p>

                  {value === "ceviche" || value === "tataki" || value === "tartare" ?
                    (
                      ''
                    ) :
                    (
                      <a
                        onClickCapture={() => !exist ? addToOrder(item) : removeOrder(item)}
                        className={exist ? "added" : ""}
                      >
                        <FontAwesomeIcon icon={faCartShopping} />
                        {exist ? <span>REMOVE FROM CART</span> : <span>ADD TO CART</span>}
                      </a>
                    )
                  }

                </div>
              )
            })
          }
        </div>
      </div>
    </section>
  )
}

export default MenuContent