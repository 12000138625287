import React from 'react'
import MenuContent from '../../../Layout/MenuContent'

function Tataki() {
  return (
    <div>
      <MenuContent value="tataki" />
    </div>
  )
}

export default Tataki