import React from 'react'

// - Import menu pages
import MenuStarters from '../Pages/Menu/ALaCarte/Starters'
import MenuPokeBowls from '../Pages/Menu/ALaCarte/PokeBowls'
import MenuRiceDishes from '../Pages/Menu/ALaCarte/RiceDishes'
import MenuSashimi from '../Pages/Menu/ALaCarte/Sashimi'
import MenuNigiri from  '../Pages/Menu/ALaCarte/Nigiri'
import MenuTemaki from '../Pages/Menu/ALaCarte/Temaki'
import MenuHosomaki from '../Pages/Menu/ALaCarte/Hosomaki'
import MenuHotSushiRoll from '../Pages/Menu/ALaCarte/HotSushiRoll'
import MenuIsoUramakiRoll from '../Pages/Menu/ALaCarte/IsoUramakiRoll'
import MenuTsukijiSpecial from '../Pages/Menu/ALaCarte/TsukijiSpecial'
import MenuSushiPlatter from '../Pages/Menu/ALaCarte/SushiPlatter'
import MenuCeviche from '../Pages/Menu/ALaCarte/Ceviche'
import MenuTartare from '../Pages/Menu/ALaCarte/Tartare'
import MenuTataki from '../Pages/Menu/ALaCarte/Tataki'

// - Import order pages
import PickUpOrder from '../Pages/Order/PickUpOrder'
import DeliveryOrder from '../Pages/Order/DeliveryOrder'

const alacarteRoute = [
    { path: 'starters', name: 'Starters', element: MenuStarters },
    { path: 'pokebowls', name: 'Poke Bowls', element: MenuPokeBowls },
    { path: 'ricedishes', name: 'Rice Dishes', element: MenuRiceDishes },
    { path: 'sashimi', name: 'Sashimi', element: MenuSashimi },
    { path: 'nigiri', name: 'Nigiri', element: MenuNigiri },
    { path: 'temaki', name: 'Temaki', element: MenuTemaki },
    { path: 'hosomaki', name: 'Hosomaki', element: MenuHosomaki },
    { path: 'hotsushiroll', name: 'Hot Sushi Roll', element: MenuHotSushiRoll },
    { path: 'isouramakiroll', name: 'Iso Uramaki Roll', element: MenuIsoUramakiRoll },
    { path: 'tsukijispecial', name: 'Tsukiji Special', element: MenuTsukijiSpecial },
    { path: 'sushiplatter', name: 'Sushi Platter', element: MenuSushiPlatter },
    { path: 'ceviche', name: 'Ceviche', element: MenuCeviche },
    { path: 'tartare', name: 'Tartare', element: MenuTartare },
    { path: 'tataki', name: 'Tataki', element: MenuTataki },
]

const orderRoute = [
    { path: 'pick-up', name: 'Pick-up Orders', element: PickUpOrder },
    { path: 'delivery', name: 'Delivery Orders', element: DeliveryOrder },
]

export { alacarteRoute, orderRoute }