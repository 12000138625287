import React from 'react'
import MenuContent from '../../../Layout/MenuContent'

function Tartare() {
  return (
    <div>
      <MenuContent value="tartare" />
    </div>
  )
}

export default Tartare