import React from 'react'

// react router
import { Link } from 'react-router-dom'

function MenuLandingPage() {
    return (
        <div className="container" data-aos="fade-up">
            <div className="section-header">
                <p className="fw-bold">Check our Tsukiji Menu</p>
            </div>
            <div className="tab-content" data-aos="fade-up" data-aos-delay="300">
                <div className="row gy-4 d-flex justify-content-center">
                    <div className="col-sm-4 mb-3 mb-sm-0">
                        <Link to="alacarte">
                            <div className="card rounded-0">
                                <div className="card-body text-center p-0">
                                    <img src="/assets/img/foods/alacarte.jpg "/>
                                    <p className="card-title text-uppercase fw-bold my-auto">A la carte</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-sm-4 mb-3 mb-sm-0">
                        <a href="assets/AYCE.pdf" target="_blank">
                            <div className="card rounded-0">
                                <div className="card-body text-center p-0">
                                    <img src="/assets/img/foods/buffet.jpg" />
                                    <p className="card-title text-uppercase fw-bold my-auto">All-You-Can-Eat</p>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MenuLandingPage