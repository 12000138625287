import React from 'react'
import MenuContent from '../../../Layout/MenuContent'

function Ceviche() {
  return (
    <div>
      <MenuContent value="ceviche" />
    </div>
  )
}

export default Ceviche