import React from 'react'

// component
import MenuContent from '../../../Layout/MenuContent'

function PokeBowls() {
  
  return (
    <div>
      <MenuContent value="poke bowls" />
    </div>
  )
}

export default PokeBowls