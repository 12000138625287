import React from 'react'

function LoadingSpinner() {
  return (
    <div className="d-flex justify-content-center align-items-center h-full" style={{height: '100vh'}}>
      <div className="spinner-border text-danger" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  )
}

export default LoadingSpinner