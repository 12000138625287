import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";

// Styling
import Aos from "aos";
import "aos/dist/aos.css";

//Import Pages
import { Suspense } from "react";
import LoadingSpinner from "./Layout/LoadingSpinner";
const Layout = React.lazy(() => import("./Layout"));
const ErrorPage = React.lazy(() => import("./Pages/ErrorPage"));
const Home = React.lazy(() => import("./Pages/Home"));
const Menu = React.lazy(() => import("./Pages/Menu"));
const Order = React.lazy(() => import("./Pages/Order/Order"));
const Contact = React.lazy(() => import("./Pages/Contact"));
const Locations = React.lazy(() => import("./Pages/Locations"));
const Booking = React.lazy(() => import("./Pages/Booking"));
const Checkout = React.lazy(() => import("./Pages/Checkout"));
import MenuAlacarte from "./Pages/Menu/ALaCarte";
import MenuLandingPage from "./Pages/Menu/MenuLandingPage";
import AlacarteLandingPage from "./Pages/Menu/ALaCarte/AlacarteLandingPage";

// routes
import { alacarteRoute, orderRoute } from "./_utils/routes";

function App() {
  useEffect(() => {
    Aos.init({
      duration: 1000,
      easing: "ease-in-out",
      once: true,
      mirror: false,
    });
  }, []);

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Routes>
        <Route path="/" name="Layout" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="menu" element={<Menu />} >
            <Route index element={<MenuLandingPage />} />
            <Route path="alacarte" element={<MenuAlacarte />}>
              <Route index element={<AlacarteLandingPage />} />
              {alacarteRoute.map((route, index) => {
                return (
                  <Route
                    key={index}
                    path={route.path}
                    name={route.name}
                    element={<route.element />}
                  />
                );
              })}
            </Route>
          </Route>
          <Route path="order" element={<Order />} />
          <Route path="contact" element={<Contact />} />
          <Route path="locations" element={<Locations />} />
          <Route path="booking" element={<Booking />} />
          <Route path="cart" element={<Checkout />} />
        </Route>
        <Route exact path="*" name="Page not found" element={<ErrorPage />} />
      </Routes>
    </Suspense>
  );
}

export default App;
