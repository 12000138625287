import React from 'react'
import MenuContent from '../../../Layout/MenuContent'

function HotSushiRoll() {
  return (
    <div>
      <MenuContent  value="hot sushi roll" />
    </div>
  )
}

export default HotSushiRoll