import React from 'react'
import MenuContent from '../../../Layout/MenuContent'

function Temaki() {
  return (
    <div>
      <MenuContent value="temaki" />
    </div>
  )
}

export default Temaki