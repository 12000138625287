import React from 'react'
import MenuContent from '../../../Layout/MenuContent'

function Nigiri() {
  return (
    <div>
      <MenuContent value="nigiri" />
    </div>
  )
}

export default Nigiri