import React from 'react';

// component
import MenuContent from '../../../Layout/MenuContent';

function RiceDishes() {
  return (
    <div>
      <MenuContent value="rice dishes" />
    </div>
  )
}

export default RiceDishes