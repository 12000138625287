import React from 'react'

// component
import MenuContent from '../../../Layout/MenuContent'

function Starters() { 
  return (
    <div>
      <MenuContent value="starters" />
    </div>
  )
}

export default Starters