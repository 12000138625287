import React from 'react'
import { Link, NavLink, Outlet, Route } from 'react-router-dom';

import foodItems from '../../../_utils/food';

const AlacarteLandingPage = () => {
  return (
    <div className="container" data-aos="fade-up">
      <div className="section-header">
        <p className="fw-bold">Check our Tsukiji Menu</p>
      </div>
      <div className="tab-content" data-aos="fade-up" data-aos-delay="300">
        <div className="row gy-4 d-flex justify-content-center">
          {
              foodItems.map((foodSection, index) => {
                return (
                  <div className="col-sm-4 mb-3 mb-sm-0" key={index}>
                    <Link to={foodSection.url}>
                    <div className="card rounded-0">
                      <div className="card-body text-center p-0">
                        <img src={foodSection.imgSrc} />
                        <p className="card-title text-uppercase fw-bold my-auto">{foodSection.label}</p>
                      </div>
                    </div>
                    </Link>
                  </div>
                )
              })
          }
        </div>
      </div>
    </div>
  )
}
export default AlacarteLandingPage