import React from 'react'
import { Outlet, useOutletContext } from 'react-router-dom';

const MenuList = () => {
  const [updateOrderCount] = useOutletContext();
  return (
    <>
      <Outlet context={[updateOrderCount]}/>
    </>
  )
}

export default MenuList