import React from 'react'
import MenuContent from '../../../Layout/MenuContent'

function Hosomaki() {
  return (
    <div>
      <MenuContent value="hosomaki" />
    </div>
  )
}

export default Hosomaki