import React from 'react'
import MenuContent from '../../../Layout/MenuContent'

function SushiPlatter() {
  return (
    <div>
      <MenuContent value="sushi platter" />
    </div>
  )
}

export default SushiPlatter