import React from 'react'
import MenuContent from '../../../Layout/MenuContent'

function TsukijiSpecial() {
  return (
    <div>
      <MenuContent value="tsukiji special" />
    </div>
  )
}

export default TsukijiSpecial